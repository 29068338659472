import { gql } from "@apollo/client";

import { IBrokerDetails, IMsUser, BrokerRole } from "../types/User";

export interface IGetBrokerDetailsResponse {
  getBrokerDetails: IBrokerDetails;
}

export interface IGetBrokerDetailsRequest {
  userId: string;
}

export interface IUploadUserProfilePhotoMutationRequest {
  file?: File;
}

export interface IUploadUserProfilePhotoMutationResponse {
  uploadUserPhoto?: string;
}

export interface IBrokerPaginationResponse {
  getBrokersPage: IBrokersPage;
}

export interface ISetBrokerRoleRequest {
  input: { organizationId: string; brokerId: string; role: BrokerRole };
}
export interface ISetBrokerRoleResponse {
  successful: boolean;
}

interface IBrokersPage {
  brokers: IMsUser[];
  count: number;
}

export interface IBrokerPaginationRequest {
  organizationId: string;
}

export const GET_BROKER_DETAILS = gql`
  query GetBrokerDetails($userId: String!) {
    getBrokerDetails(userId: $userId) {
      brokerId
      role
      city
      givenName
      mail
      mobilePhone
      postalCode
      state
      streetAddress
      streetAddress2
      surName
      accountEnabled
      organizations {
        id
        created
        type
        name
        phoneNumber
        status
        address
        city
        state
        zipcode
        brokers {
          brokerId
          role
          city
          givenName
          mail
          mobilePhone
          postalCode
          state
          streetAddress
          streetAddress2
          surName
          accountEnabled
        }
        agencies {
          id
          name
          status
          brokers {
            role
            givenName
            surName
          }
        }
      }
      photoId
      npn
      isOnboardingCompleted
      sellingStates
      licenseStatuses {
        expirationDate
        state
        status {
          status
          color
        }
        details {
          loa
          issueDate
          status
        }
      }
      clearCertStatuses {
        state
        status
        reason
        throughDate
        statusIndicator
      }
      licenseAppointments {
        company
        status
        reasonDate
        renewalDate
      }
    }
  }
`;

export const UPLOAD_USER_PHOTO_MUTATION = gql`
  mutation uploadUserPhoto($file: Upload!) {
    uploadUserPhoto(file: $file)
  }
`;

export const GET_BROKERS_PAGE = gql`
  query GetBrokersPage($organizationId: ID!) {
    getBrokersPage(organizationId: $organizationId) {
      brokers {
        brokerId
        mail
        surName
        givenName
        accountEnabled
        role
      }
      count
    }
  }
`;

export const SET_BROKER_ROLE = gql`
  mutation setBrokerRole($input: InputSetBrokerRole!) {
    setBrokerRole(input: $input)
  }
`;

export interface ISetBrokerStatusMutationResponse {
  setBrokerStatus: boolean;
}

export interface ISetBrokerStatusMutationRequest {
  brokerUsername: string;
  active: boolean;
}

export const SET_BROKER_STATUS = gql`
  mutation setBrokerStatus($brokerUsername: String!, $active: Boolean!) {
    setBrokerStatus(brokerUsername: $brokerUsername, active: $active)
  }
`;

export interface IVerifyEmailRequest {
  verificationCode: string;
}
export interface IVerifyEmailResponse {
  verifyEmail: boolean;
}

export const VERIFY_EMAIL = gql`
  mutation verifyEmail($verificationCode: String!) {
    verifyEmail(verificationCode: $verificationCode) {
      status
    }
  }
`;

export interface IGetUserRoles {
  id: string;
}

export const GET_USER_ROLES = gql`
  query getBrokerPermissons($id: String!) {
    getBrokerPermissions(brokerId: $id)
  }
`;

export interface InputSsnLastFourLookup {
  brokerId: string;
  lastName: string;
  firstName: string;
  ssnLastFour: string;
}

export interface IGetBrokerSsnLastFourLookupRequest {
  input: InputSsnLastFourLookup;
}

export interface IGetBrokerSsnLastFourLookupResponse {
  getBrokerSsnLastFourLookup?: string;
}

export const GET_BROKER_SSN_LAST_FOUR_LOOKUP = gql`
  query getBrokerSsnLastFourLookup($input: InputSsnLastFourLookup!) {
    getBrokerSsnLastFourLookup(input: $input)
  }
`;
