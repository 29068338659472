import React, { ReactNode, useState } from "react";
import { useContext } from "react";

interface IComingSoonContext {
  comingSoonModalShown: boolean;
  setComingSoonModalShown: React.Dispatch<React.SetStateAction<boolean>>;
}

export const ComingSoonContext = React.createContext<IComingSoonContext>({
  comingSoonModalShown: false,
  setComingSoonModalShown: () => {},
});

const ComingSoonContextProvider = ({ children }: { children: ReactNode }) => {
  const [comingSoonModalShown, setComingSoonModalShown] =
    useState<boolean>(false);

  return (
    <ComingSoonContext.Provider
      value={{
        comingSoonModalShown,
        setComingSoonModalShown,
      }}
    >
      {children}
    </ComingSoonContext.Provider>
  );
};

export const useComingSoonContext = () => useContext(ComingSoonContext);

export default ComingSoonContextProvider;
