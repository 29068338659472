import React, { ReactNode, useContext, useEffect, useState } from "react";
import { Permission } from "@/constants/permission";
import { GET_USER_ROLES, IGetUserRoles } from "@/queries/User";
import { useLazyQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { BrokerRole } from "@/types/User";
import NavbarLayout from "@/layouts/NavBarLayout";
import { Loader } from "@reframe-financial/chaplin";
import {
  getBrokerRole,
  getUserId,
  getUserRole,
  isLoggedIn,
} from "@/services/auth";
import { RoleGroup } from "@/types/RoleGroup";
import { ClipLoader } from "react-spinners";

type PermissionTarget =
  | Permission
  | ((permissionList: Permission[], role: BrokerRole) => boolean);

interface IPermissionContext {
  permissions: Permission[];
  updatePermissions: (brokerId: string) => Promise<void>;
  hasPermission: (target: PermissionTarget) => boolean;
  registerAdminLogin: () => void;
}

export const PermissionContext = React.createContext<IPermissionContext>({
  hasPermission: (target: PermissionTarget) => false,
  updatePermissions: async (brokerId) => {},
  registerAdminLogin: () => {},
  permissions: [],
});

export const PermissionContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [permissions, setPermissions] = useState<Permission[]>([]);
  const [loading, setLoading] = useState(true);
  const [brokerRole, setBrokerRole] = useState<BrokerRole | undefined>();
  const [cognitoGroup, setCognitoGroup] = useState<string>();

  const [getRoles] = useLazyQuery<
    { getBrokerPermissions: Permission[] },
    IGetUserRoles
  >(GET_USER_ROLES);

  const hasPermission = (target: PermissionTarget): boolean => {
    if (cognitoGroup === "admin-group") return true;

    if (typeof target === "function") {
      return target(permissions, brokerRole!);
    }

    return permissions.includes(target as Permission);
  };

  const updatePermissions = async (brokerId: string) => {
    const { data } = await getRoles({
      variables: {
        id: brokerId,
      },
    });
    if (!data || !data.getBrokerPermissions) {
      toast.error("Could not get user roles");
      return;
    } // TODO error handling?
    setPermissions(data.getBrokerPermissions);
  };

  const registerAdminLogin = () => {
    // if admin logged, getUserRole will return 'admin-group' we should just get it to the local state
    setCognitoGroup(getUserRole());
  };

  useEffect(() => {
    async function getUserPermissions() {
      try {
        if (!isLoggedIn()) return;

        if (getUserRole() === RoleGroup.Admin) {
          return setCognitoGroup(RoleGroup.Admin);
        }

        const role = getBrokerRole() as BrokerRole;
        setBrokerRole(role);

        const brokerId = getUserId();

        await updatePermissions(brokerId);
      } finally {
        setLoading(false);
      }
    }

    getUserPermissions();
  }, []);

  const providerValue: IPermissionContext = {
    hasPermission,
    updatePermissions,
    registerAdminLogin,
    permissions,
  };
  return (
    <PermissionContext.Provider value={providerValue}>
      {loading ? (
        <NavbarLayout logoDark={false} childrenContainerStyles="max-w-7xl">
          <div className="h-screen flex items-center justify-center">
            <Loader icon={<ClipLoader />} />
          </div>
        </NavbarLayout>
      ) : (
        children
      )}
    </PermissionContext.Provider>
  );
};

export const usePermissionContext = () => useContext(PermissionContext);
